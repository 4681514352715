<template>
  <div class="flex flex-row items-center gap-4">
    <div class="w-6">
      <UiIcon name="user-group" class="text-black-70" />
    </div>
    <UiInputChips
      v-model="vModel.participants"
      name="guests"
      placeholder="Guests"
      compact
      ghost
      :error="useGetFieldErrors(vuelidate, ['participants'])"
    />
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import type { Activity } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: Activity
  vuelidate: Validation
}

const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)
</script>

<style scoped></style>
