<template>
  <div class="h-full">
    <div
      class="relative z-[2] flex flex-col gap-1 border-b border-solid border-black-10 p-3 xs:px-8 xs:pb-4 xs:pt-10 xs:shadow-md"
    >
      <div class="flex flex-row items-center xs:justify-between">
        <UiButtonGhost id="day_calendar_prev" class="xs:hidden" @click="calendarRef.prev()">
          <UiIcon name="chevron-small-left" class="text-black-70" />
        </UiButtonGhost>
        <div v-if="currentDateRange" class="text-subhead-4 xs:text-subhead-2">
          {{ format(currentDateRange.start, 'eeee, MMMM do') }}
        </div>
        <div class="flex flex-row items-center justify-center gap-1">
          <UiButtonGhost id="day_calendar_prev" class="hidden xs:flex" @click="calendarRef.prev()">
            <UiIcon name="chevron-small-left" class="text-black-70" />
          </UiButtonGhost>
          <UiButtonGhost id="day_calendar_next" @click="calendarRef.next()">
            <UiIcon name="chevron-small-right" class="text-black-70" />
          </UiButtonGhost>
        </div>
        <UiIcon
          :name="showCalendar ? 'eye' : 'eye-off'"
          class="ml-auto xs:hidden"
          @click="showCalendar = !showCalendar"
        />
      </div>
      <UiTagPrimary
        v-if="user"
        id="user"
        left-icon="info-circle-filled"
        right-icon=""
        icon-color="text-additional-1-120"
        background-color-class="bg-additional-1-30"
        compact
      >
        <span class="text-caption-2 text-black-100">
          {{ user.name }}
        </span>
      </UiTagPrimary>
    </div>
    <Collapse :when="showCalendar" class="height-transition h-full">
      <div class="h-[157px] px-3 xs:h-[calc(100%_-_97px)] xs:pl-8">
        <UiCalendar
          ref="calendarRef"
          :key="Number(showCalendar)"
          :initial-view="CALENDAR_VIEWS.DAY"
          :filters="{ user_id: user?.id }"
          :get-activities="getActivities"
          hide-day-headers
          :initial-date="initialDate"
          :read-only="true"
          :event-color="user ? '#FFBA72' : ''"
          :local-events="localEvents"
          @updated:dates="currentDateRange = $event"
          @selected:date="$emit('selected:date', $event)"
        />
      </div>
    </Collapse>
  </div>
</template>

<script setup lang="ts">
import { Collapse } from 'vue-collapsed'
import { format } from 'date-fns'
import type { Activity, DateRange, User } from '@/types'
import { CALENDAR_VIEWS } from '@/constants'

defineEmits(['selected:date'])

const showCalendar = ref(true)
const calendarRef = ref()
const currentDateRange = ref<DateRange>()

type Props = {
  initialDate?: Date | string
  localEvents?: Activity[]
  user?: Partial<User>
  getActivities?: boolean
}

defineProps<Props>()

const getEvents = () => {
  calendarRef.value.getEvents()
}
const gotoDay = (date: Date) => {
  calendarRef.value?.gotoDay(date)
}

defineExpose({
  getEvents,
  gotoDay,
})
</script>

<style scoped></style>
